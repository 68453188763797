const config = {
  calendars: [
    {
      id: 1,
      apiKey: "AIzaSyCZWVtOWcSPTLqhNEUZW1A8HD16iFjb2WQ",
      calendarId: "jasonmichaelhatfield"
    },
    {
      id: 2,
      apiKey: "AIzaSyCqbtpMVA2F5JZyLyWe_dv8dqPgdqd9hLY",
      calendarId: "yorknjrotc1993"
    },
    {
      id: 3,
      apiKey: "AIzaSyCZWVtOWcSPTLqhNEUZW1A8HD16iFjb2WQ",
      calendarId: "yorknjrotc1993"
    }
  ]
};

export default config;
