/*

Navy Term
Civilian Term
Port Left
Starboard Right
Mess Deck Cafeteria
Galley Kitchen
Aft Back
Ladder Stairway/steps
Head Bathroom
Passageway Hallway
Deck Floor
Overhead Ceiling
Bulkhead Wall
Stern Back-end
Bow Front-end
Hull Outside of ship
Keel Bottom of ship
Beam Widest part of ship
Superstructure Ship structure above main deck
Helm Steering wheel
Porthole Window
Scuttlebutt Water Fountain
Compartment Room
Rack Bed
Berthing Enlisted Bedroom
Stateroom Officer Bedroom
Wardroom Officer Eating Area
Aloft To climb above the highest floor
 */

const NavyTerminology = () => {
  return (
    <div>
      <h1>Navy Terminology</h1>
      <table>
        <thead>
        <tr>
          <th>Navy Term</th>
          <th>Civilian Term</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Port</td>
          <td>Left</td>
        </tr>
        <tr>
          <td>Starboard</td>
          <td>Right</td>
        </tr>
        <tr>
          <td>Mess Deck</td>
          <td>Cafeteria</td>
        </tr>
        <tr>
          <td>Galley</td>
          <td>Kitchen</td>
        </tr>
        <tr>
          <td>Aft</td>
          <td>Back</td>
        </tr>
        <tr>
          <td>Ladder</td>
          <td>Stairway/steps</td>
        </tr>
        <tr>
          <td>Head</td>
          <td>Bathroom</td>
        </tr>
        <tr>
          <td>Passageway</td>
          <td>Hallway</td>
        </tr>
        <tr>
          <td>Deck</td>
          <td>Floor</td>
        </tr>
        <tr>
          <td>Overhead</td>
          <td>Ceiling</td>
        </tr>
        <tr>
          <td>Bulkhead</td>
          <td>Wall</td>
        </tr>
        <tr>
          <td>Stern</td>
          <td>Back-end</td>
        </tr>
        <tr>
          <td>Bow</td>
          <td>Front-end</td>
        </tr>
        <tr>
          <td>Hull</td>
          <td>Outside of ship</td>
        </tr>
        <tr>
          <td>Keel</td>
          <td>Bottom of ship</td>
        </tr>
        <tr>
          <td>Beam</td>
          <td>Widest part of ship</td>
        </tr>
        <tr>
          <td>Superstructure</td>
          <td>Ship structure above main deck</td>
        </tr>
        <tr>
          <td>Helm</td>
          <td>Steering wheel</td>
        </tr>
        <tr>
          <td>Porthole</td>
          <td>Window</td>
        </tr>
        <tr>
          <td>Scuttlebutt</td>
          <td>Water Fountain</td>
        </tr>
        <tr>
          <td>Compartment</td>
          <td>Room</td>
        </tr>
        <tr>
          <td>Rack</td>
          <td>Bed</td>
        </tr>
        <tr>
          <td>Berthing</td>
          <td>Enlisted Bedroom</td>
        </tr>
        <tr>
          <td>Stateroom</td>
          <td>Officer Bedroom</td>
        </tr>
        <tr>
          <td>Wardroom</td>
          <td>Officer Eating Area</td>
        </tr>
        <tr>
          <td>Aloft</td>
          <td>To climb above the highest floor</td>
        </tr>
        </tbody>
      </table>
    </div>
  );
}

export default NavyTerminology;