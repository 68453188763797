/*
Cadet Creed

I am a Navy Junior ROTC Cadet.
  I strive to promote patriotism and become an informed and responsible citizen.
  I respect those in positions of authority.
  I support those who have gone before me to protect freedom and democracy around the world.
  I proudly embrace the Navy core values of Honor, Courage and Commitment.
  I am committed to excellence and fair treatment of all.
 */

const CadetCreed = () => {
  return (
    <div>
      <h1>Cadet Creed</h1>
        <p>I am a Navy Junior ROTC Cadet.</p>
        <p>I strive to promote patriotism and become an informed and responsible citizen.</p>
        <p>I respect those in positions of authority.</p>
        <p>I support those who have gone before me to protect freedom and democracy around the world.</p>
        <p>I proudly embrace the Navy core values of Honor, Courage and Commitment.</p>
        <p>I am committed to excellence and fair treatment of all.</p>
    </div>
  );
}

export default CadetCreed;