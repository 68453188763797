/*
Chain of Command
President of the United States
https://www.whitehouse.gov/administration/president-biden/

Secretary of Defense
https://www.defense.gov/About/Secretary-of-Defense/

Secretary of the Navy
https://www.navy.mil/Leadership/Secretary-of-the-Navy/

Chief of Naval Operations
https://www.navy.mil/Leadership/Chief-of-Naval-Operations/

Master Chief Petty Officer of the Navy
https://www.navy.mil/Leadership/Flag-Officer-Biographies/BioDisplay/Article/3151928/

Commander, Naval Education and Training Command
https://www.netc.navy.mil/Media-Center/Biographies/Leadership-Biography/Article/3422621/rear-admiral-jeffrey-czerewko/
Commander, Navy Service Training Command (NSTC)
Reference:https://www.netc.navy.mil/NSTC/commnader/html/

NJROTC Area 6 Manager
Reference:https://www.netc.navy.mil/Commands/Naval-Service-Training-Command/NJROTC/NJROTC-Geographic-Areas/

YCHS NJROTC Unit Commander (Senior Naval Science Instructor, SNSI)

YCHS NJROTC Unit Chief (Naval Science Instructor, NSI)

YCHS NJROTC Cadet Commanding Officer

YCHS NJROTC Cadet Executive Officer
 */

const ChainOfCommand = () => {
  return (
    <div>
      <h1>Chain of Command</h1>
      <ul>
        <li><a href="https://www.whitehouse.gov/administration/president-biden/">President of the United States</a></li>
        <li><a href="https://www.defense.gov/About/Secretary-of-Defense/">Secretary of Defense</a></li>
        <li><a href="https://www.navy.mil/Leadership/Secretary-of-the-Navy/">Secretary of the Navy</a></li>
        <li><a href="https://www.navy.mil/Leadership/Chief-of-Naval-Operations/">Chief of Naval Operations</a></li>
        <li><a href="https://www.navy.mil/Leadership/Flag-Officer-Biographies/BioDisplay/Article/3151928/">Master Chief Petty Officer of the Navy</a></li>
        <li><a href="https://www.netc.navy.mil/Media-Center/Biographies/Leadership-Biography/Article/3422621/rear-admiral-jeffrey-czerewko/">Commander, Naval Education and Training Command</a></li>
        <li><a href="https://www.netc.navy.mil/NSTC/commnader/html/">Commander, Navy Service Training Command (NSTC)</a></li>
        <li><a href="https://www.netc.navy.mil/Commands/Naval-Service-Training-Command/NJROTC/NJROTC-Geographic-Areas/">NJROTC Area 6 Manager</a></li>
        <li>YCHS NJROTC Unit Commander (Senior Naval Science Instructor, SNSI)</li>
        <li>YCHS NJROTC Unit Chief (Naval Science Instructor, NSI)</li>
        <li>YCHS NJROTC Cadet Commanding Officer</li>
        <li>YCHS NJROTC Cadet Executive Officer</li>
      </ul>
    </div>
  );
}

export default ChainOfCommand;